import React from 'react'

import { AppContextProps } from '@/core/contexts/app'
import type { PageComponent } from '@/services/http/wap/page'
import components from '@/components'

export const render = (component: PageComponent, context: AppContextProps) => {
  const { id, name, props, refs, children } = component

  if (name === 'view') {
    const view = context.page.views[id]

    if (view) {
      return (
        <React.Fragment key={id}>
          {view.map((component) => render(component, context))}
        </React.Fragment>
      )
    }
  } else {
    const Component = components(name)

    if (Component) {
      if (children.length) {
        return (
          <Component key={id} {...props} {...refs}>
            {children.map((child) => render(child, context))}
          </Component>
        )
      } else {
        return <Component key={id} {...props} {...refs} />
      }
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`Missing component definitions (${name}): `, { props, refs })
    }
  }
}

export default render
