import { NodeEnv } from '@/constants/enums'
import { EnvironmentContext } from '@/core/classes/environment'

/**
 * The ``app`` function returns the fully qualified path to the app directory.
 * You may also use the ``app`` function to generate a fully qualified path
 * to a given file relative to the application directory.
 *
 * @param {string} pathname Added directory path
 * @returns {string}
 */

export const app = (context: EnvironmentContext, pathname?: string): string => {
  const origin = context.url || '/'

  if (!pathname || typeof pathname !== 'string') return origin

  if (pathname.indexOf('http') === 0) {
    return pathname
  }

  if (context.environment === NodeEnv.Production) {
    return `/${pathname}`
  }

  return `/${context.key}/${pathname}`
}

export const url = (context: EnvironmentContext, pathname?: string): string => {
  const origin = context.url || '/'

  if (context.environment === NodeEnv.Production) {
    return `${origin}/${pathname}`
  }

  return `${origin}/${context.key}/${pathname}`
}

export const asset = (context: EnvironmentContext, path?: string): string => {
  if (!path) return ''

  if (path.indexOf('http') !== -1) {
    return path
  } else {
    return `${context.cdn}/${path}`
  }
}
