import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Footer = dynamic(() => import('./widgets/footer'))
const Header = dynamic(() => import('./widgets/header'))
const Banner = dynamic(() => import('./widgets/banner'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Image = dynamic(() => import('./base/image'))
const Characters = dynamic(() => import('./widgets/characters'))
const Videos = dynamic(() => import('./widgets/videos'))
const News = dynamic(() => import('./widgets/news'))
const NewsList = dynamic(() => import('./widgets/news-list'))
const VideoList = dynamic(() => import('./widgets/video-list'))
const VideoListDetail = dynamic(() => import('./widgets/video-list-detail'))
const About = dynamic(() => import('./widgets/about'))
const Events = dynamic(() => import('./widgets/events'))
const Content = dynamic(() => import('./widgets/content'))
const Link = dynamic(() => import('./widgets/link'))

export default function getter(name: string) {
  switch (name) {
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'container':
      return Container
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'piril-header':
      return Header
    case 'piril-footer':
      return Footer
    case 'piril-banner':
      return Banner
    case 'piril-karakterler':
      return Characters
    case 'piril-videolar':
      return Videos
    case 'piril-haberler':
      return News
    case 'piril-video-list':
      return VideoList
    case 'piril-hakkinda':
      return About
    case 'piril-etkinlikler':
      return Events
    case 'piril-content':
      return Content
    case 'piril-video-list-detail':
      return VideoListDetail
    case 'piril-haberler-list':
      return NewsList
    case 'piril-link':
      return Link
  }
}
